export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    padding: '1rem 2rem',
    borderColor: 'primary',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    ':hover': {
      backgroundColor: 'transparent'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    padding: '1rem 2rem',
    borderColor: 'primary',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
