export default {
  sideBySide1: {
    img: {
      maxHeight: ['500px', '', '', '100vh']
    },
    margin: '0 auto',
    padding: ['1rem 0rem', , '0px'],
    minHeight: ['', '', '', '100vh'],
    '.content': {},
    '.title': {
      fontSize: ['2rem', '', '3rem'],
      letterSpacing: '2px'
    },
    '.text': {
      fontSize: '1.25rem',
      maxWidth: '700px',
      '*': {
        lineHeight: '1.75'
      }
    },
    '.linksContainer': {
      mt: '2rem',
      '.ctaButton': {
        variant: 'buttons.primary'
      },
      '.secondaryCtaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  centerBlock: {
    color: 'white',
    textAlign: 'center',
    padding: '8rem 1rem',
    '.title': {
      fontSize: ['2rem', '', '3rem'],
      letterSpacing: '2px'
    },
    '.subtitle': {
      fontSize: '2rem'
    },
    '.text': {
      fontSize: '1.25rem',
      maxWidth: '700px',
      '*': {
        lineHeight: '1.75'
      }
    },

    '.linksContainer': {
      mt: '2rem',
      '.ctaButton': {
        variant: 'buttons.primary'
      },
      '.secondaryCtaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  sectionNavLinks: {
    backgroundColor: 'background',
    '.boxes': {
      maxWidth: 'unset'
    },
    '.lazyload-wrapper': {
      height: ['350px', , '500px'],
      margin: ['1rem', , '0rem'],
      '&:hover a h2': {
        backgroundColor: '#2b2b2b'
      }
    }
  }
}
